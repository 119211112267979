/* TODO: Fix Loader position in commons */
.Loader {
  justify-content: start !important;
  align-items: start !important;
}


@media (max-width: 768px) {
  .signature-pad canvas {
    height: 55vh !important;
    width: 100% !important;
  }
}
